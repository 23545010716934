import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detail/:listid',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },   
  {
    path: '/post',
    name: 'post',
    component: () => import('../views/PostView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/ad',
    name: 'homeAd',
    component: HomeView
  },
  {
    path: '/ad/detail/:listid',
    name: 'detailAd',
    component: () => import('../views/DetailView.vue')
  },   
  {
    path: '/ad/post',
    name: 'postAd',
    component: () => import('../views/PostView.vue')
  },
  {
    path: '/ad/about',
    name: 'aboutAd',
    component: () => import('../views/AboutView.vue')
  },
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHashHistory(process.env.VUE_APP_HOM_URL),
  routes
})

export default router
