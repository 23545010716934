<template>
  <div class="top-nav">
    <el-row :gutter="10" justify="space-around">
      <el-col :xs="{span:4, push:1}" :sm="{span:4, push:1}" :md="{span:4, push:1}" :lg="{span:4, push:1}" :xl="{span:4, push:1}">
        <div @click="handleClick">
          <el-image class="top-nav-image" fit="fill" :src="logoUrl" alt="homapp_logo" />
          <span class="top-nav-title">&nbsp;HOMAPP</span>
        </div>
      </el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="12" :xl="12">
        <el-image style="width:160px; height:40px; padding-top: 8px" fit="fill" :src="metroUrl" />
        &nbsp;
        <span class="top-nav-metroname">{{metroName}}</span>
        <el-dropdown @command="handleCommand">
          <el-icon class="top-nav-metrolist"><arrow-down /></el-icon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="0">{{metroOptions[0].label}}</el-dropdown-item>
              <el-dropdown-item command="1">{{metroOptions[1].label}}</el-dropdown-item>
              <el-dropdown-item command="2">{{metroOptions[2].label}}</el-dropdown-item>
              <el-dropdown-item command="3">{{metroOptions[3].label}}</el-dropdown-item>
              <el-dropdown-item command="4">{{metroOptions[4].label}}</el-dropdown-item>
              <el-dropdown-item command="5">{{metroOptions[5].label}}</el-dropdown-item>
              <!-- <el-dropdown-item command="6">{{metroOptions[6].label}}</el-dropdown-item>
              <el-dropdown-item command="7">{{metroOptions[7].label}}</el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
      <el-col :xs="{span:4, pull:1}" :sm="{span:4, pull:1}" :md="{span:4, pull:1}" :lg="8" :xl="8">
          <div  class="top-nav-menu">
          <div v-if="isLargeScreen">
            <el-menu mode="horizontal" :default-active="activeIndex" @select="handleSelect">
              <el-menu-item index="1"><span class="top-nav-menu-item">{{menu[0]}}</span></el-menu-item>
              <el-menu-item index="2"><span class="top-nav-menu-item">{{menu[1]}}</span></el-menu-item>
              <el-menu-item index="3"><span class="top-nav-menu-item">{{menu[2]}}</span></el-menu-item>
              <el-menu-item index="6">
                <el-switch 
                  v-model="switchValue" 
                  inline-prompt
                  active-text="EN"
                  inactive-text="中" 
                  size="large" 
                  style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                />
              </el-menu-item>
            </el-menu>
          </div>
          <div v-else>
            <el-button @click="handleOpen"><el-icon><Fold /></el-icon></el-button>
            <el-drawer v-model="isOpenDrawer" :show-close="false" :append-to-body="true" size="40%" :before-close="handleClose">
              <el-menu mode="vertical" :default-active="activeIndex" @select="handleSelect">
                <el-menu-item index="1"><span class="top-nav-menu-item">{{menu[0]}}</span></el-menu-item>
                <el-menu-item index="2"><span class="top-nav-menu-item">{{menu[1]}}</span></el-menu-item>
                <el-menu-item index="3"><span class="top-nav-menu-item">{{menu[2]}}</span></el-menu-item>
                <el-menu-item index="6">
                  <el-switch 
                    v-model="switchValue" 
                    inline-prompt
                    active-text="EN"
                    inactive-text="中" 
                    size="large" 
                    style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                  />
                </el-menu-item>
              </el-menu>
            </el-drawer>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { ArrowDown, Fold } from '@element-plus/icons-vue'
import { Lang } from '@/utils/language'
import { Consts } from '@/utils/consts'
import { getMetroList } from '@/utils/api'
export default {
  name: 'TopNav',
  components: { ArrowDown, Fold },
  data() {
    return {
      isLargeScreen: this.$store.state.largeScreen,
      isOpenDrawer: false,
      isAdPublisher: false,
      switchValue: true,
      logoUrl: require('@/assets/logo.png'),
      metroUrl: 'https://storage.googleapis.com/metropolitan.homapp.com/vancouver/metro-city-logo.jpg',
      metroName: 'Greater Vancouver',
      metroOptions: Consts.Metropolitan,
    }
  },
  computed: {
    activeIndex() {
      return this.$store.state.menuActiveIndex;
    },
    menu() {
      if (this.switchValue) {
        this.$store.commit('changeLang', 'en');
        return Lang.Menu.en;
      } else {
        this.$store.commit('changeLang', 'cn');
        return Lang.Menu.cn;
      };
    },
  },
  mounted() {
    const href = window.location.href;
    if (href.indexOf('/ad') > 0) {
      this.isAdPublisher = true;
    }

    getMetroList(this.metroName).then(res => {
      this.$store.commit('changeMetroName', this.metroName);
      this.$store.commit('changeMetroCity', res.data);
    }).catch(err => {
      console.log('Not get metro city');
    });
  },
  methods: {
    handleOpen(){
      this.isOpenDrawer = true;
    },
    handleClose(){
      this.isOpenDrawer = false;
    },    
    handleClick() {
      window.open('https://www.homapp.com', '_blank');
    },
    handleSelect(idx) {
      if (this.isAdPublisher) {
        console.log('Ad');
        switch(idx) {
          case '1':
            this.$router.push({ 
              path: '/ad',
            });
            break;
          case '2':
            this.$router.push({ 
              path: '/ad/post',
              // query: {
              //   contact: 'HOMAPP',
              //   phone: '778-383-3519',
              //   email: 'info@homapp',
              //   wechat: 'homapp',
              // },
            });
            break;
          case '3':
            this.$router.push({ 
              path: '/ad/about',
            });
            break;
          default:
        }
      } else {
        console.log('No Ad');
        switch(idx) {
          case '1':
            this.$router.push({ 
              path: '/',
            });
            break;
          case '2':
            this.$router.push({ 
              path: '/post',
              // query: {
              //   contact: 'HOMAPP',
              //   phone: '778-383-3519',
              //   email: 'info@homapp',
              //   wechat: 'homapp',
              // },
            });
            break;
          case '3':
            this.$router.push({ 
              path: '/about',
            });
            break;
          default:
        }
      }
      this.handleClose();
    },
    handleCommand(cmd) {
      // let city = this.metroOptions[cmd].label.replace('Greater ', '').replace(' Region', '').replace('–Gatineau', '')
      // this.metroUrl = require('@/assets/' + city + '-of-City.png');
      // this.metroName = this.metroOptions[cmd].label;

      let city = this.metroOptions[cmd].label.replace('Greater ', '').replace(' Region', '').replace('–Gatineau', '').toLowerCase();
      this.metroUrl = `https://storage.googleapis.com/metropolitan.homapp.com/${city}/metro-city-logo.jpg`;
      this.metroName = this.metroOptions[cmd].label;

      getMetroList(this.metroName).then(res => {
        this.$store.commit('changeMetroName', this.metroName);
        this.$store.commit('changeMetroCity', res.data);
      }).catch(err => {
        console.log('Not get metro city');
      });
    }
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.top-nav {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  z-index: 1;
  height: $top-height;
  border-bottom: 1px solid rgba(144, 147, 153, 0.2);
  background-color: white;
}
.top-nav-image {
  padding-top: 8px;
  width:50px; 
  height:50px;
  vertical-align: middle;
  cursor: pointer;
}
.top-nav-title {
  font-size: 2em;
  font-weight: 700;
  color: $theme-color;
  vertical-align: middle;
  cursor: pointer;
}
.top-nav-metroname {
  padding: 20px 0;
  font-size: 1.5em;
  font-weight: 700;
}
.top-nav-metrolist {
  padding-top: 30px;
}
.top-nav .el-image {
  text-align: left;
}
.top-nav .el-switch {
  text-align: right;
}
.top-nav-menu {
  padding-top: 10px;
}
.top-nav-menu-item {
  font-size: 1.3em;
}

@media screen and (min-width: 1201px) {

}
@media screen and (max-width: 1200px) {
.top-nav-title {
  display: none;
}
.top-nav-metroname {
  display: none;
}
}
</style>
