<template>
  <top-nav />
  <router-view v-slot="{ Component }">
    <transition>
      <keep-alive exclude="DetailView">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
  <bottom-claim />
</template>


<script>
import TopNav from '@/components/TopNav'
import BottomClaim from '@/components/BottomClaim'
export default {
  components: { TopNav, BottomClaim },
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
</style>
