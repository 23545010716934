import { createStore } from 'vuex'

export default createStore({
  state: {
    largeScreen: window.innerWidth > 1200 ? true : false,
    lang: 'en',
    metroName: 'Greater Vancouver',
    metroCity: [],
    menuActiveIndex: '1',
  },
  getters: {
  },
  mutations: {
    changeScreen(state) {
      if (window.innerWidth > 1200) {
        state.largeScreen = true;
      } else {
        state.largeScreen = false;
      }
    },    
    changeLang(state, val) {
      state.lang = val
    },
    changeMetroName(state, val) {
      state.metroName = val;
    },
    changeMetroCity(state, val) {
      let arr = val[Object.keys(val)[0]];
      let str = JSON.stringify(arr).replaceAll('city', 'label').replaceAll('metro_area', 'value');
      state.metroCity = JSON.parse(str);
      for (let i=0; i<state.metroCity.length; i++) {
        state.metroCity[i].value = i+1;
      }
    },
    changeMenuActiveIndex(state, val) {
      state.menuActiveIndex = val
    },    
  },
  actions: {
  },
  modules: {
  }
})
