<template>
  <div class="bottom">
    <el-row :gutter="30" justify="center">
      <el-col :xs="{span:6, offset:2}" :sm="{span:6, offset:2}" :md="{span:6, offset:2}" :lg="{span:1, offset:1}" :xl="{span:1, offset:1}">
        <div class="bottom-logo">
          <a href="https://www.fairycow.ca" alt="Developed by: FairyCOW.ca" target="_blank"> <el-avatar :size="80" :fit="'fill'" :src="logoUrl" /> </a>
        </div>
      </el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="5" :xl="5">
        <div class="bottom-info">
          HOMAPP Website power by FairyCOW Software Inc., who is qualified DDF<sup>®</sup> Technology Provider by CREA
        </div>
      </el-col>
      <el-col :xs="{span:22, offset:1}" :sm="{span:22, offset:1}" :md="{span:22, offset:1}" :lg="{span:16, pull:1}" :xl="{span:16, pull:1}">
        <div class="bottom-claim">
          <a href="https://www.realtor.ca/en" alt="Powered by: REALTOR.ca" target="_blank"> <img width="90" height="40" src="https://www.realtor.ca/images/en-ca/powered_by_realtor.svg"/></a>
          The trademarks REALTOR<sup>®</sup>, REALTORS<sup>®</sup> and the REALTOR<sup>®</sup> logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.
          The trademarks MLS<sup>®</sup>, Multiple Listing Service<sup>®</sup> and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA.
          The trademark DDF<sup>®</sup> is also owned by CREA and identifies the REALTOR.ca Data Distribution Facility (DDF<sup>®</sup>).
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  name: 'BottomClaim',
  data() {
    return {
      logoUrl: require('@/assets/fairycow-logo.png')
    }
  },
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.bottom {
  z-index: 1;  
  background-color: $theme-color;
  color: white;
  font-size: 1em;
  text-align: left;
}
.bottom-logo {
  padding: 30px 0;
}
.bottom-info {
  padding: 45px 0 0 20px;
}
.bottom-claim {
  padding: 20px 0;
}
</style>