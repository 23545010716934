<template>
  <div class="list-item" @click="handleClick">
    <el-card>
      <el-row :gutter="40">
        <el-col :xs="24" :sm="24" :md="24" :lg="15" :xl="15">
          <address class="item-address">{{address}}, {{city}}</address>
          <div class="item-property">
            <span class="item-dwelling">{{dwelling}}</span>
            <span v-if="price == 0" class="item-price">{{itemLabel[4]}}</span>
            <span v-else class="item-price">$ {{price}}</span>
          </div>
          <div class="item-info">
            <div v-if="pet == true">
              {{bedrooms}} {{itemLabel[0]}}  |  {{bathrooms}} {{itemLabel[1]}}  |  {{parkings}} {{itemLabel[2]}}  |  {{itemLabel[3]}}
            </div>
            <div v-else>
              {{bedrooms}} {{itemLabel[0]}}  |  {{bathrooms}} {{itemLabel[1]}}  |  {{parkings}} {{itemLabel[2]}}  |  <span class="item-info-pet">{{itemLabel[3]}}</span>
            </div>
          </div>
          <div class="item-title">
            {{title}}
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9">
          <div class="container">
            <el-image class="image" style="width:100%; height:260px" :fit="'fill'" :src="thumbnailUrl" />
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>


<script>
import { Lang } from '@/utils/language'
export default {
  name: 'ListItem',
  props: {
    rowid: {
      required: true,
      type: Number
    },
    itemData: {
      required: true,
      type: Object
    },
  },
  data() {
    return {
      idx: this.rowid,
      thumbnailUrl: this.itemData.album_array[0],
      address: this.itemData.address,
      city: this.itemData.city,
      dwelling: this.itemData.property_type,
      price: this.itemData.price,
      bedrooms: this.itemData.bedrooms,
      bathrooms: this.itemData.bathrooms,
      parkings: this.itemData.parkings,
      pet: this.itemData.pet == 'Not Allow' ? false : true, 
      title: this.itemData.title,
    }
  },
  computed: {
    itemLabel() {
      if (this.$store.state.lang == 'en') {
        return Lang.PropertyList.en;
      } else {
        return Lang.PropertyList.cn;
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('on-click', this.idx);
    }
  }
}
</script>


<style lang="scss" scoped>
.list-item {
  // padding: 20px 10%;
  text-align: left;
}
.item-address {
  padding: 40px 0 0 0;
  font-size: 1.7em;
  font-weight: 600;
}
.item-property {
  padding: 15px 50px 0 0;
  font-size: 1.3em;
}
.item-dwelling {
  text-align: left;
}
.item-price {
  float: right;
  text-align: right;
  color: red;
}
.item-info {
  padding: 15px 0;
  font-size: 1.3em;
}
.item-info-pet {
  color: grey;
}
.item-title {
  padding: 30px 0;
  font-size: 1.3em;
}
.container {
  position: relative;
}
.image {
  display: block;
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute; 
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  bottom: 0; 
  width: 333px;
  opacity:1;
  padding: 20px;
  color: red;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
</style>