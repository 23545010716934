import { request } from '@/utils/request'
import requestEx from '@/utils/requestEx'

export function getMetroList(params = {}) {
  let data = `metro_area=${params}`;
  return request({
    method: 'GET',
    api: 'metro_area',
    data: data,
  })
}

export function getRentalList(metroName, params = {}) {
  let data = `metro_area=${metroName}&from=${params.from}&size=${params.size}`;
  let str = JSON.stringify(params.filters);
  if (str != '{}') {
    str = str.toLowerCase();
    data += str.replaceAll(':', '=').replaceAll(',"','&').replaceAll(',', '')
        .replaceAll('"', '').replace('{', '&').replace('}', '')    
        .replace('dwelling', 'property_type').replace('/condo', '').replace('bedrooms', 'bed_rooms')
        .replace('price', 'price_min').replace('-$', '&price_max=').replace('$', '');
  }

  return request({
    method: 'GET',
    api: 'rental_list',
    data: data,
  })
}

export function getRentalDetail(params = {}) {
  return request({
    method: 'GET',
    api: 'rental_single',
    data: `list_id=${params.listid}`
  });
}

// const signature = `api_key=${Consts.ApiKey}&timestamp=1645985268&api_signature=1a6357b507f155e0aa823353baae2077c2de4f568bcf9a4babd314df46d0fdcf`
const signature = `user_id=2`
export function updateRental(params = {}) {
  let str = JSON.stringify(params);
  if (str != '{}') {
    str = str.toLowerCase();
    data += str.replaceAll(':', '=').replaceAll(',"','&')/*.replaceAll(',', '')*/.replaceAll(/[|]/g, '')
        .replaceAll('"', '').replace('{', '&').replace('}', '')    
        .replace('dwelling', 'property_type').replace('/condo', '')
        .replace('price', 'rent').replace('pics', 'album_array=').replace('facility', 'term_slug_array')
        .replace('rentInclude=', '').replace('water', 'water_included=Y').replace('electricity', 'hydro_included=Y').replace('gas', 'gas_included=Y').replace('wifi', 'internet_included=Y')
        .replace('contact=', '').replace('name', 'contact_name').replace('phone', 'contact_phone').replace('email', 'contact_email').replace('wechat', 'contact_wechat_id');
  }

  let data = str + `&${params}`;
  return request({
    method: 'POST',
    api: 'rental_create',
    data: signature + data,
  })
}

export function updateRentalEx(params = {}) {
  return requestEx({
    // url: 'lease.crawl',
    url: 'crawl.lease',
    method: 'post',
    data: params
  })
}
