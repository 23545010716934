<template>
  <div class="home">
    <div class="filter">
      <div v-if="isLargeScreen">
        <h1>{{title}}</h1>
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="{span:3, offset:3}" :xl="{span:3, offset:3}">
            <el-select v-model="filters.city" size="default" placeholder="City">
              <el-option v-for="item in metroCity" :key="item.value" :label="item.label" :value="item.label" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
            <el-select v-model="filters.dwelling" size="default" placeholder="Dwelling">
              <el-option v-for="item in dwellingOptions" :key="item.value" :label="item.label" :value="item.label" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
            <el-select v-model="filters.price" size="default" placeholder="Price">
              <el-option v-for="item in priceRange" :key="item.value" :label="item.label" :value="item.label" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
            <el-select v-model="filters.bedrooms" size="default" placeholder="Bedroom">
              <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
            <el-select v-model="filters.bathrooms" size="default" placeholder="Bathroom">
              <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
            <el-select v-model="filters.pet" size="default" placeholder="Pet">
              <el-option v-for="item in petAllow" :key="item.value" :label="item.label" :value="item.label" />
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3" class="filter-button">
            <el-button circle @click="handleSearch">
              <el-icon style="vertical-align: middle"><Search /></el-icon>
            </el-button>
            <el-button circle @click="handleReset">
              <el-icon><Refresh /></el-icon>
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <h3>
          <el-button circle @click="handleOpenDrawer"><el-icon><Filter /></el-icon></el-button>
          &nbsp;&nbsp;{{title}}&nbsp;&nbsp;
          <!-- <el-image style="width: 40px; height: 40px; cursor: pointer; justify-content: center; align-items: center;" :src="mapIconUrl" fit="fill" @click="handleMap" /> -->
        </h3>        
        <el-drawer v-model="isOpenDrawer" direction="ltr" :show-close="false" :append-to-body="true" size="60%" :before-close="handleCloseDrawer">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="{span:3, offset:3}" :xl="{span:3, offset:3}">
              <el-select v-model="filters.city" size="default" placeholder="City">
                <el-option v-for="item in metroCity" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-select v-model="filters.dwelling" size="default" placeholder="Dwelling">
                <el-option v-for="item in dwellingOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-select v-model="filters.price" size="default" placeholder="Price">
                <el-option v-for="item in priceRange" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-select v-model="filters.bedrooms" size="default" placeholder="Bedroom">
                <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-select v-model="filters.bathrooms" size="default" placeholder="Bathroom">
                <el-option v-for="item in 10" :key="item" :label="item" :value="item" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3">
              <el-select v-model="filters.pet" size="default" placeholder="Pet">
                <el-option v-for="item in petAllow" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="3" class="filter-button">
              <el-button circle @click="handleSearch">
                <el-icon style="vertical-align: middle"><Search /></el-icon>
              </el-button>
              <el-button circle @click="handleReset">
                <el-icon><Refresh /></el-icon>
              </el-button>
            </el-col>
          </el-row>
        </el-drawer>
      </div>
    </div>
    <div class="list">
      <div v-if="isLargeScreen">
        <el-row>
          <el-col :span="4" class="aside">
            <el-affix :offset="250">
              <el-link :href="iosUrl" target="_blank">
                <el-image style="width:207px; height:69px" fit="fill" :src="appStoreLogoUrl" />
              </el-link>
              <el-link :href="androidUrl" target="_blank">
                <el-image style="width:207px; height:69px" fit="fill" :src="googlePlayLogoUrl" />
              </el-link>
            </el-affix>
          </el-col>
          <el-col :span="16" class="content">
            <div v-if="listTotal > 0">
              <div v-for="(item, idx) in list" :key="item">
                <div v-if="'banner_id' in item">
                  <!-- 信息流广告 -->
                </div>
                <div v-else>
                  <list-item :rowid="idx" :itemData="item" :tag="'None'" @on-click="handleDetail" />
                </div>
              </div>
            </div>
            <div v-else>
              <h3>Result is loading</h3>
              <!-- 展示广告 -->
              <ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-6261615651830378"
                data-ad-slot="8989683715"
                data-ad-format="auto"
                data-full-width-responsive="true">
              </ins>
            </div>
          </el-col>
          <el-col :span="4" class="aside">
            <el-affix :offset="250">
              <!-- 展示广告 -->
              <ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-6261615651830378"
                data-ad-slot="8989683715"
                data-ad-format="auto"
                data-full-width-responsive="true">
              </ins>
            </el-affix>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <div class="aside">
          <el-row>
            <el-col :span="12">
              <el-link :href="iosUrl" target="_blank">
                <el-image style="width:80%; height:80%" fit="fill" :src="appStoreLogoUrl" />
              </el-link>
            </el-col>
            <el-col :span="12">
              <el-link :href="androidUrl" target="_blank">
                <el-image style="width:80%; height:80%" fit="fill" :src="googlePlayLogoUrl" />
              </el-link>
            </el-col>
          </el-row>
        </div>
        <div class="content">
          <div v-if="listTotal > 0">
            <div v-for="(item, idx) in list" :key="item">
              <div v-if="'banner_id' in item">
                <!-- 信息流广告 -->
              </div>
              <div v-else>
                <list-item :rowid="idx" :itemData="item" :tag="'None'" @on-click="handleDetail" />
              </div>
            </div>
          </div>
          <div v-else>
            <h3>Result is loading</h3>
            <!-- 展示广告 -->
            <ins class="adsbygoogle"
              style="display:block"
              data-ad-client="ca-pub-6261615651830378"
              data-ad-slot="8989683715"
              data-ad-format="auto"
              data-full-width-responsive="true">
            </ins>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination layout="prev, pager, next" :pager-count="11" :page-count="pageTotal" 
        @current-change="handlePageChange" 
        @prev-click ="handlePrevClick" 
        @next-click="handleNextClick" 
      />
    </div>
  </div>
</template>


<script>
import { Filter, Search, Refresh } from '@element-plus/icons-vue'
import { Lang } from '@/utils/language'
import { Consts } from '@/utils/consts'
import { getRentalList } from '@/utils/api'
import ListItem from '@/components/ListItem'
export default {
  name: 'ListView',
  components: { Filter, Search, Refresh, ListItem },
  data() {
    return {
      isLargeScreen: this.$store.state.largeScreen,
      isOpenDrawer: false,

      // mapIconUrl: require('@/assets/map-icon.png'),
      appStoreLogoUrl: require('@/assets/app-store-logo.png'),
      googlePlayLogoUrl: require('@/assets/google-play-logo.png'),
      iosUrl: Consts.IosApp,
      androidUrl: Consts.AndroidApp,
      dwellingOptions: Consts.DwellingOptions,
      priceRange: Consts.PriceRange,
      petAllow: Consts.PetAllow,
      filters: {
        city: '',
        dwelling: '',
        price: '',
        bedrooms: '',
        bathrooms: '',
        pet: '',
      },
      list: [],
      listTotal: 0,
      listQuery: {
        page: 1,
        from: 0,
        size: Consts.PageSize,
        filters: {},
      },
    }
  },
  computed: {
    title() {
      if (this.$store.state.lang == 'en') {
        return Lang.Title.en[0];
      } else {
        return Lang.Title.cn[0];
      }
    },
    pageTotal() {
      return Math.ceil(this.listTotal / Consts.PageSize);
    },
    metroName() {
      return this.$store.state.metroName;
    },
    metroCity() {
      return this.$store.state.metroCity;
    },
  },
  watch: {
    metroName(newVal, oldVal) {
      // this.$store.commit('changeMenuActiveIndex', '1');
      this.getList();
    }
  },
  mounted() {
    // if (this.$route.query && this.$route.query.metro) {
    //   this.$store.commit('changeMetroName', this.$route.query.metro);
    // }
    this.$store.commit('changeMenuActiveIndex', '1');
    this.getList();
  },
  methods: {
    getList() {
      this.listQuery.filters = this.filters;
      this.listQuery.from = (this.listQuery.page-1) * (this.listQuery.size-1);
      getRentalList(this.metroName, this.listQuery).then(res => {
        this.list = res.properties;
        this.listTotal = res.results_found;
      }).catch(err => {
        this.list = [];
        this.listTotal = 0;
      })
    },
    handleDetail(idx) {
      this.$router.push({
        name: 'detail',
        params: {
          listid: this.list[idx].ID
        }
      })      
    },
    handlePageChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handlePrevClick() {
      if (this.listQuery.page > 1) {
        this.listQuery.page--;
      }
      this.getList();
    },
    handleNextClick() {
      if (this.listQuery.page < this.pageTotal) {
        this.listQuery.page++;
      }
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    handleReset() {
      this.filters.city = '';
      this.filters.dwelling = '';
      this.filters.price = '';
      this.filters.bedrooms = '';
      this.filters.bathrooms = '';
      this.filters.pet = '';
    },
    handleOpenDrawer() {
      this.isOpenDrawer = true;
    },
    handleCloseDrawer() {
      this.isOpenDrawer = false;
    },    
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
.filter {
  padding: 10px 0 20px 0;
  border-bottom: px solid rgba(144, 147, 153, 0.2);
  background-color: $theme-color;
  color: black;
}
.filter-button {
  text-align: left;
}
.list {
  margin: auto;
  width: 100%;
}
.aside {
  margin: 10px 10px;
  text-align: center;
}
.content {
  text-align: left;
}
.pagination {
  display: flex;
  justify-content: center;  /*水平居中*/
  align-items: center;      /*垂直居中*/
}
</style>