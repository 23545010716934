const HmcUploadUrl = process.env.VUE_APP_HMC_URL + '/upload'; // 文件存储地址
const HmcApiUrl = process.env.VUE_APP_HMC_URL + '/api';       // API接口地址
const HmcApiUid = process.env.VUE_APP_HMC_API_UID;
const HmcApiToken = process.env.VUE_APP_HMC_API_TOKEN;
const HmcApiSecret = process.env.VUE_APP_HMC_API_SECRET;

const HomApiUrl = process.env.VUE_APP_HOM_URL;
const HomApiKey = process.env.VUE_APP_HOM_API_KEY;
const HomApiSecret = process.env.VUE_APP_HOM_API_SECRET
const PageSize = 16;
const IosApp = 'https://apps.apple.com/app/id879209391';
const AndroidApp = 'https://play.google.com/store/apps/details?id=ca.city365.homapp';
const Metropolitan = [
  {
    value: 1,
    label: 'Greater Vancouver',
  },
  {
    value: 2,
    label: 'Greater Victoria',
  },
  {
    value: 3,
    label: 'Calgary Region',
  },
  {
    value: 4,
    label: 'Edmonton Region',
  },
  {
    value: 5,
    label: 'Ottawa–Gatineau',
  },
  {
    value: 6,
    label: 'Greater Toronto',
  },
  // {
  //   value: 7,
  //   label: 'Greater Montreal',
  // },
  // {
  //   value: 8,
  //   label: 'Quebec Region',
  // },
];
const ProvinceOptions = [
  {
    value: 1,
    label: 'Aberta',
  },
  {
    value: 2,
    label: 'British Columbia',
  },
  {
    value: 3,
    label: 'Monitoba',
  },
  {
    value: 4,
    label: 'New Brunswick',
  },
  {
    value: 5,
    label: 'Newfoundland and Labrador',
  },
  {
    value: 6,
    label: 'Nova Scott',
  },
  {
    value: 7,
    label: 'Ontario',
  },
  {
    value: 8,
    label: 'Prince Edward Island',
  },
  {
    value: 9,
    label: 'Quebec',
  },
  {
    value: 10,
    label: 'Saskatchewan',
  },
];
const DwellingOptions = [
  {
    value: 1,
    label: 'House',
  },
  {
    value: 2,
    label: 'Duplex',
  },
  {
    value: 3,
    label: 'Townhouse',
  },
  {
    value: 4,
    label: 'Apartment/Condo',
  }
];
const PriceRange = [
  {
    value: 1000,
    label: 'Over $10,000,000',
  },
  {
    value: 600,
    label: '$6,000,000-$9,999,999',
  },
  {
    value: 500,
    label: '$5,000,000-$5,999,999',
  },
  {
    value: 400,
    label: '$4,000,000-$4,999,999',
  },
  {
    value: 300,
    label: '$3,000,000-$3,999,999',
  },
  {
    value: 200,
    label: '$2,000,000-$2,999,999',
  },
  {
    value: 100,
    label: '$1,000,000-$1,999,999',
  },
  {
    value: 50,
    label: '$500,000-$999,999',
  },
  {
    value: 10,
    label: '$100,000-$499,999',
  }
];
const PetAllow = [
  {
    value: 1,
    label: 'Not Allowed',
  },
  {
    value: 2,
    label: 'Dog Only',
  },
  {
    value: 3,
    label: 'Cat Only',
  },
  {
    value: 4,
    label: 'Both',
  }
]
const RentOptions = ['Electricity', 'Water', 'Gas', 'Internet'];
const FacilityOptions = ['Fridge', 'Microwave', 'Stove', 'Dishwasher', 'Washer', 'Dry', 'Conditioner', 'Heating', 'Fan', 'Security', 'Pool', 'Furniture'];
const LeaseTerm = [
  {
    value: 'L',
    label: 'Long Term',
  },
  { 
    value: 'S',
    label: 'Short Term',
  }
];
const LeaseClass = ['Property', 'Floor', 'Suite', 'Room'];
const LeaseValidityPeriod = [7, 15, 30];
const LeaseLanguage = ['CN', 'EN'];


export const Consts = {
  HmcUploadUrl,  
  HmcApiUrl,
  HmcApiUid,
  HmcApiToken,
  HmcApiSecret,
  HomApiUrl,
  HomApiKey,
  HomApiSecret,
  PageSize,
  IosApp,
  AndroidApp,
  Metropolitan,
  ProvinceOptions,
  DwellingOptions,
  PriceRange,
  PetAllow,
  RentOptions,
  FacilityOptions,
  LeaseLanguage,
  LeaseTerm,
  LeaseClass,
  LeaseValidityPeriod,
} 
