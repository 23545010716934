const Menu = {
  en: ['Rent', 'Post', 'About'],
  cn: ['租房', '发布', '关于'],
};
const Title = {
  en: ['Rental Property Listing', 'Free-post Rental Property', 'About Us'],
  cn: ['出租房源', '免费发布出租房源', '关于我们'],
};
const Property = {
  en: ['Facility', 'Detail', 'Description'],
  cn: ['相关设施', '详情信息', '房源介绍'],
};
const PropertyList = {
  en: ['Bedrooms', 'Bathrooms', 'Parkings', 'Pet', 'Negotiable'],
  cn: ['睡房', '卫浴', '车位', '宠物', '面议'],
};
const PropertyDetail = {
  en: ['Number', 'Address', 'City', 'Dwelling', 'Floor Area', 'Listing Date'],
  cn: ['编号', '地址', '城市', '房源类型', '使用面积', '上市日期'],
};
const TravelInfo = {
  en: ['Travel index', 
    "Disclaimer: The higher the score, the more convenient the corresponding travel way."
  ],
  cn: ['出行指数', 
    '免责声明：分值越高，对应的交通方式越便利'
  ],
};
const SchoolInfo = {
  en: ['School catchment', 'Elementary', 'Secondary', 'km distance',
    "Disclaimer: This school catchment data is based in whole or in part on data generated by the Metroplitan cities & school districts and school ranking is from Fraser Institute,  which HOMAPP.COM assumes no resposibility for its accuracy."
  ],
  cn: ['校区信息', '小学', '中学', '公里距离',
    '免责声明：以上校区信息，根据大都会的各市政府或教育部门信息由系统自动匹配完成，学校排名信息来自Fraser Institute。所有数据仅提供参考，HOMAPP.COM不为其准确性负责，对相应的二手房源信息不作任何入升学承诺。'
  ],
};
const MapInfo = {
  en: ['Map view','Drag the human icon into map for StreetView mode, ESC to exit StreetView mode', 'Road Map', 'Street View'],
  cn: ['地图视角', '将人形图标拖入地图切换到街景模式，ESC退出街景模式', '道路图', '街景图'],
};
const SimilarListing = {
  en: ['Similar listing'],
  cn: ['相似房源'],
};
const Post = {
  en: ['Autofill', 'Publish', 'Rent', 'Facilities'],
  cn: ['自动填信息', '发布', '租金', '屋内设备'],
};
const PostProperty = {
  en: ['Title', 'Please input rental property introduction','Dwelling-Type', 'Bedrooms', 'Bathrooms', 'Parkings', 'Pets', 'Floor-Area'],
  cn: ['标题', '请输入出租房源的介绍', '房型', '睡房', '卫浴', '车位', '宠物', '使用面积'],
};
/*
const PostRent = {
  en: ['Electricity', 'Water', 'Gas', 'Internet'],
  cn: ['电费', '水费', '气费', '网费'],
};
const PostFacility = {
  en: ['Fridge', 'Microwave', 'Stove', 'Dishwasher', 'Washer', 'Dry', 'Conditioner', 'Heating', 'Fan', 'Security', 'Pool', 'Furniture'],
  cn: ['冰箱', '微波炉', '灶炉', '洗碗机', '洗衣机', '干衣机', '空调', '暖气', '风扇', '安防', '游泳池', '家具'],
};
*/
const PostLease = {
  en: ['Lease-Class', 'Lease-Term', 'Avaliable-Date', 'Publish-Period'],
  cn: ['出租形式', '出租周期', '可住日期', '发布周期'],
};
const PostContact = {
  en: ['Contact', 'Phone', 'Email', 'Wechat'],
  cn: ['联系人', '电话', '邮箱', '微信'],
};
const AboutUs = {
  en: [
    "HOMAPP.COM is the website version of the HOMAPP App, which provides you with detailed information on properties for sale and rent in Canada's major metropolitan areas, and recommends licensed real estate agents for you.", 
    'HOMAPP.COM only provides technical services for recommended real estate agents, does not participate in any real estate transaction related services of real estate agents, and is not responsible for any real estate transaction process and results of real estate agents.', 
    'If you have any technical questions, please contact us by e-mail: info@homapp.com'
  ],
  cn: [
    'HOMAPP.COM是地产知道APP的网站版本，为您提供详尽的加拿大的主要大都会区在售和在租的房产信息，并为您推荐持牌的房产经纪人。', 
    'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。', 
    '您有任何技术问题请通过电子信箱联系我们：info@homapp.com'
  ],
}


export const Lang = {
  Menu,
  Title,
  Property,
  PropertyList,
  PropertyDetail,
  TravelInfo,
  SchoolInfo,
  MapInfo,
  SimilarListing,
  Post,
  PostProperty,
  // PostRent,  
  // PostFacility,
  PostLease,
  PostContact,
  AboutUs,
}